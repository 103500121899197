import BaseLayoutTemplate from '../components/environments/BaseLayoutTemplate';
import LazyLoadImage from '../components/atoms/LazyLoadImage';
import React from 'react';
import styled from 'styled-components';
import { breakpoint } from '../components/theme';
import {
  BGImg,
  CROCCHA_API,
  MetaDesc,
  MetaOGP,
  Support1_1Img,
  Support1_2Img,
  Support2_1Img,
  Support2_2Img,
  Support2_3Img,
  Support3_1Img,
  Support3_2Img,
} from '../utils';
import useMedia from '../customHooks/userMedia';
import HeadBase from '../components/molecules/HeadBase';

import ContactServiceForm from '../components/organisms/ContactSearviceForm';
import OtherService from '../components/molecules/OtherService';

const ITSupportPage = () => {
  const isMobile = useMedia('(max-width: 767px)');
  return (
    <BaseLayoutTemplate>
      <HeadBase
        metas={{
          title: 'IT・DX支援 | tryangle株式会社',
          description: MetaDesc,
          openGraph: {
            type: 'article',
            url: `${CROCCHA_API.SELF_DOMAIN}/it-support/`,
            title: 'IT・DX支援 | tryangle株式会社',
            description: MetaDesc,
            images: [
              {
                url: MetaOGP,
                width: 1200,
                height: 630,
                alt: 'tryangle株式会社',
              },
            ],
            site_name: 'tryangle株式会社',
          },
        }}
      />
      <Title>IT・DX支援</Title>
      {/* ミッション */}
      <SectionVision>
        {isMobile ? (
          <Vision>
            ハンドメイドSNS「croccha」の運用で培った
            <br />
            技術力を生かしてIT支援を行ってきました。
            <br />
            crocchaで運用しているネットショップ、SNSサービスのノウハウを生かして、
            社内の専属エンジニアがSEO対策、セキュリティ対策、ユーザビリティ等
            サービス運用に配慮したサイト制作や運用のお手伝いをさせていただきます。
          </Vision>
        ) : (
          <Vision>
            ハンドメイドSNS「croccha」の運用で培った技術力を生かして
            <br />
            IT支援を行ってきました。
            <br />
            crocchaで運用しているネットショップ、SNSサービスのノウハウを生かして、
            <br />
            社内の専属エンジニアがSEO対策、セキュリティ対策、ユーザビリティ等
            <br />
            サービス運用に配慮したサイト制作や運用のお手伝いをさせていただきます。
          </Vision>
        )}
      </SectionVision>

      {/* 開発実績 */}
      <BGWrap url={BGImg}>
        <SectionTitleWhite>開発実績</SectionTitleWhite>
        <DevResults>
          <DevResultBox>
            <h3>EC-CUBEによるネットショップ開発</h3>
            <p>
              ネットショップをEC-CUBEにて開発しました。
              <br />
              2時間でカート内の商品を在庫確保するなどの特殊要件にも対応し、要件定義やデザイン、カスタマイズ開発、保守まで全て社内で開発しています。
            </p>
            <ul>
              <TechKeyword color='#026E00'>EC-CUBE</TechKeyword>
              <TechKeyword color='#026E00'>Symfony</TechKeyword>
              <TechKeyword color='#3178C6'>PHP</TechKeyword>
              <TechKeyword color='#FF9D00'>AWS</TechKeyword>
              <TechKeyword color='#61DAFB'>jQuery</TechKeyword>
            </ul>
          </DevResultBox>
          <DevResultBox>
            <h3>WordPressでのHP開発</h3>
            <p>
              受験生向けの学校紹介サイトをWordPressで制作しました。
              <br />
              お子さんと学校の相性診断ができる機能などを開発しています。
            </p>
            <ul>
              <TechKeyword color='#026E00'>WordPress</TechKeyword>
              <TechKeyword color='#3178C6'>PHP</TechKeyword>
              <TechKeyword color='#61DAFB'>HTML/CSS</TechKeyword>
              <TechKeyword color='#FF9D00'>レンタルサーバ</TechKeyword>
              <TechKeyword color='#61DAFB'>jQuery</TechKeyword>
            </ul>
          </DevResultBox>
          <DevResultBox>
            <h3>Next.jsでのSNSアプリケーション開発</h3>
            <p>
              Next.jsにてハンドメイド作品の投稿、閲覧ができるSNSアプリケーションを開発しました。
              <br />
              作り方などのマガジン機能もヘッドレスCMSであるContentfulを選定することで短期間で実装しています。
            </p>
            <ul>
              <TechKeyword color='#026E00'>Next.js</TechKeyword>
              <TechKeyword color='#3178C6'>React</TechKeyword>
              <TechKeyword color='#3178C6'>TypeScript</TechKeyword>
              <TechKeyword color='#FF9D00'>AWS</TechKeyword>
              <TechKeyword color='#026E00'>Node.js</TechKeyword>
              <TechKeyword color='#61DAFB'>Contentful</TechKeyword>
            </ul>
          </DevResultBox>
          <DevResultBox>
            <h3>React Nativeによるハイブリッドアプリ開発</h3>
            <p>
              Android、iOS両対応のSNSアプリを開発しました。
              <br />
              Expoを用いて開発工数の削減を実現したり、ネットショップのAPIとの連携により、アプリ上でスムーズにハンドメイド作品を購入する機能などを開発しています。
            </p>
            <ul>
              <TechKeyword color='#026E00'>React Native</TechKeyword>
              <TechKeyword color='#3178C6'>React</TechKeyword>
              <TechKeyword color='#3178C6'>TypeScript</TechKeyword>
              <TechKeyword color='#FF9D00'>AWS</TechKeyword>
              <TechKeyword color='#026E00'>Node.js</TechKeyword>
            </ul>
          </DevResultBox>
          <DevResultBox>
            <h3>Gatsby.jsでのHP開発</h3>
            <p>
              Gatsby.jsを用いて静的サイトの開発を実施しました。
              <br />
              こちらもヘッドレスCMSであるContentfulの連携により、記事作成機能を短期間で開発しています。
            </p>
            <ul>
              <TechKeyword color='#026E00'>Gatsby.js</TechKeyword>
              <TechKeyword color='#3178C6'>React</TechKeyword>
              <TechKeyword color='#3178C6'>TypeScript</TechKeyword>
              <TechKeyword color='#FF9D00'>AWS</TechKeyword>
              <TechKeyword color='#61DAFB'>HTML・CSS</TechKeyword>
              <TechKeyword color='#61DAFB'>Contentful</TechKeyword>
            </ul>
          </DevResultBox>
        </DevResults>
      </BGWrap>

      {/* IT・DX支援のフロー */}
      <Section>
        <SectionTitle>IT・DX支援のフロー</SectionTitle>
        <FlowArrow>
          <li>
            <span>ヒアリング・ご提案</span>
          </li>
          <li>
            <span>システム構築</span>
          </li>
          <li>
            <span>サイト運用</span>
          </li>
        </FlowArrow>

        <FlowDetailBox>
          <h3>ヒアリング・ご提案</h3>
          <ul>
            <li>
              <FlowDetailImg src={Support1_1Img} alt='ヒアリング・調査' />
              <FlowDetailDesc>
                <h4>１、ヒアリング・調査</h4>
                <p>
                  現在の課題やご要望、ご予算をヒアリングさせていただきます。
                  <br />
                  内容を整理し、現状の課題とゴールの見える化、そのためのアクションをまとめていきます。
                </p>
              </FlowDetailDesc>
            </li>
            <li>
              <FlowDetailImg src={Support1_2Img} alt='システムのご提案' />
              <FlowDetailDesc>
                <h4>２、システムのご提案</h4>
                <p>
                  調査内容を確認しながら、システムで解決できる課題に対してご提案をさせていただきます。
                  <br />
                  もちろん運用や業務フローの改善で解決できる場合もあるかと思いますので、システム開発しないという選択肢も含めて、最もより良い形でお客様の課題を解決できるように取り組みます。
                </p>
              </FlowDetailDesc>
            </li>
          </ul>
        </FlowDetailBox>

        {/* ↓ */}
        <TriangleFlowDetail />

        <FlowDetailBox>
          <h3>システム構築</h3>
          <ul>
            <li>
              <FlowDetailImg src={Support2_1Img} alt='要件定義・設計' />
              <FlowDetailDesc>
                <h4>３、要件定義・設計</h4>
                <p>
                  要件定義をしていく中で、開発するシステムの目的を明確にし、課題の解決方法を具体的な仕様に落とし込んでいきます。
                  <br />
                  要件定義をしっかり行いつつ、決めきれないところはコミュニケーションを取りながら実装してみてから判断をするなど、効率よく柔軟な制作過程を実現します。
                </p>
              </FlowDetailDesc>
            </li>
            <li>
              <FlowDetailImg src={Support2_2Img} alt='システム開発' />
              <FlowDetailDesc>
                <h4>４、システム開発</h4>
                <p>
                  要件定義に基づき、アーキテクチャの選定や実装などの作業を進めていきます。
                  <br />
                  少数精鋭のチームのため、作業途中でも適宜お客様とのコミュニケーションの中で変更が発生した場合には、ゴールを話し合いながらすぐに軌道修正ができるようにしています。
                </p>
              </FlowDetailDesc>
            </li>
            <li>
              <FlowDetailImg src={Support2_3Img} alt='デザイン' />
              <FlowDetailDesc>
                <h4>５、デザイン</h4>
                <p>
                  社内の専属デザイナーが、ユーザーのUI・UXにも配慮したデザインを構築します。
                  <br />
                  見た目だけのデザインではなく、「ユーザーにとってより良いデザイン」を目指しています。
                </p>
              </FlowDetailDesc>
            </li>
          </ul>
        </FlowDetailBox>

        {/* ↓ */}
        <TriangleFlowDetail />

        <FlowDetailBox>
          <h3>サイト運用</h3>
          <ul>
            <li>
              <FlowDetailImg src={Support3_1Img} alt='アクセス解析' />
              <FlowDetailDesc>
                <h4>６、アクセス解析</h4>
                <p>
                  弊社にはアクセス解析の専門家が在籍しており、確かな知見を持ってアクセス解析に取り組んでいます。
                  <br />
                  必要であればGoogle AnalyticsやSearch
                  Consoleの扱い方などをレクチャーしつつ、お客様が自分たちでサイト運用ができるようにお手伝いもしています。
                </p>
              </FlowDetailDesc>
            </li>
            <li>
              <FlowDetailImg src={Support3_2Img} alt='サイト運用・改修' />
              <FlowDetailDesc>
                <h4>７、サイト運用・改修</h4>
                <p>
                  成果を最大化するために不可欠な、顧客獲得、コンバージョン、顧客維持などそれぞれについて継続的なサイト運用・改修を得意としています。
                  <br />
                  PDCAを回しながら、結果にコミットしサイト改修いたします。
                </p>
              </FlowDetailDesc>
            </li>
          </ul>
        </FlowDetailBox>
      </Section>

      {/* その他のオプション */}
      <BGGreen>
        <p>他にもこのようなご相談内容がございましたらお受けいたします</p>
        <SectionTitle>その他のオプション</SectionTitle>
        <OptionListWrap>
          <OptionList>
            <li>
              <OptionTitle>
                <span>SNS運用</span>
              </OptionTitle>
              <OptionDesc>
                今やWebマーケティングにおいて欠かせないSNS。
                <br />
                ご担当者のご負担になりがちな、日々の投稿企画・作成、コメント対応、運用分析などを一括して行います。
              </OptionDesc>
            </li>
            <li>
              <OptionTitle>
                <span>メルマガ配信代行</span>
              </OptionTitle>
              <OptionDesc>
                今日でもメルマガは強力なマーケツールとなっています。
                <br />
                投稿企画(テキスト・HTML等)、毎月の運用分析などを代行します。
              </OptionDesc>
            </li>
            <li>
              <OptionTitle>
                <span>Web広告</span>
              </OptionTitle>
              <OptionDesc>
                サイトへの導線を確保するためにWeb広告は有力な選択肢です。
                <br />
                事業・サービスに最適な広告活用をご提案させていただき、運用までを一括して行います。
              </OptionDesc>
            </li>
            <li>
              <OptionTitle>
                <span>SEO対策</span>
              </OptionTitle>
              <OptionDesc>
                長期的な集客を確保するためには、SEO対策は大きな効果を発揮します。
                <br />
                キーワードの選定から、内部・外部施策までお任せください。
              </OptionDesc>
            </li>
            <li>
              <OptionTitle>
                <span>キャンペーン企画・運営</span>
              </OptionTitle>
              <OptionDesc>
                サービス内でされている企画や運用に関してリソース不足となっている場合には、弊社で代行して行います。
                <br />
                お客様のペルソナに合わせて運用をさせていただきます。
              </OptionDesc>
            </li>
            <li>
              <OptionTitle>
                <span>その他システム保守</span>
              </OptionTitle>
              <OptionDesc>
                その他、レガシーシステムの改修、ドキュメント作成などシステムの保守に関するご要望などなんでもお問い合わせください。
                <br />
                弊社エンジニアがヒアリングし、あるべき姿を一緒に考えます。
              </OptionDesc>
            </li>
          </OptionList>
        </OptionListWrap>
      </BGGreen>

      {/* お問い合わせ */}
      <Section>
        <SectionTitle>お問い合わせ</SectionTitle>
        <ContactServiceForm service='IT・DX支援' />
      </Section>

      {/* その他のサービス */}
      <OtherService page='it-support' />
    </BaseLayoutTemplate>
  );
};

export default ITSupportPage;

const Section = styled.section`
  max-width: 800px;
  margin: 40px auto;
  text-align: center;

  :last-child {
    padding-bottom: 100px;
  }

  @media ${breakpoint.tabOnly} {
    max-width: none;
    margin: 40px 16px;
  }
`;

const Title = styled.h1`
  text-align: center;
  font-size: 32px;
  margin: 40px auto 0px;

  @media ${breakpoint.sp} {
    font-size: 24px;
  }
`;

const SectionVision = styled(Section)`
  margin: 40px auto 80px;
`;

const Vision = styled.p`
  padding: 0 36px;
  font-size: 18px;
  line-height: 32px;

  @media ${breakpoint.sp} {
    font-size: 14px;
    line-height: none;
  }
`;

const BGWrap: any = styled(Section)`
  max-width: none;
  width: 100%;
  height: 100%;
  padding-bottom: 64px;

  background-image: url(${(props: any) => props.url});
  background-position: center, center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  @media ${breakpoint.tabOnly} {
    width: 100vw;
    margin: 40px 0;
    padding: 0 16px 40px;
  }

  @media ${breakpoint.sp} {
    width: 100vw;
    margin: 40px 0;
    padding: 0 16px 40px;
  }
`;

const SectionTitle = styled.h2`
  font-size: 26px;
  padding: 30px 0;
  text-align: center;

  @media ${breakpoint.sp} {
    font-size: 22px;
  }
`;

const SectionTitleWhite = styled(SectionTitle)`
  color: #fff;
`;

const DevResults = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 32px;
  max-width: 800px;
  margin: 0 auto;

  @media ${breakpoint.tabOnly} {
    grid-gap: 16px;
  }

  @media ${breakpoint.sp} {
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }
`;

const DevResultBox = styled.li`
  background-color: #fff;
  border-radius: 10px;
  padding: 22px 24px;

  & > h3 {
    font-size: 18px;

    @media ${breakpoint.sp} {
      font-size: 16px;
    }
  }
  & > p {
    margin-top: 7px;
    font-size: 14px;
  }
  & > ul {
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 12px auto;
  }
`;

const TechKeyword: any = styled.li`
  margin: 4px;
  padding: 0 20px;
  border: 1px solid ${(props: any) => props.color};
  border-radius: 4px;
  color: ${(props: any) => props.color};

  @media ${breakpoint.sp} {
    font-size: 14px;
  }
`;

const FlowArrow = styled.ul`
  display: flex;
  justify-content: space-around;
  align-items: space-around;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;

  margin-bottom: 60px;

  & > li {
    position: relative;
    font-size: 16px;
    font-weight: bold;
    height: 50px;
    width: 30%;

    &:first-child {
      background: #fed046;
      color: #fff;
    }

    &:nth-child(2) {
      background: #5074ac;
      color: #fff;
    }
    &:last-child {
      background: #fa6d63;
      color: #fff;
    }

    @media ${breakpoint.tab} {
      &:first-child {
        &::after {
          content: '';
          position: absolute;
          top: 17px;
          left: calc(100% - 18px);
          border-right: 25px solid transparent;
          border-bottom: 15px solid #fed046;
          border-left: 25px solid transparent;
          -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
        }
      }

      &:nth-child(2) {
        &::before {
          content: '';
          position: absolute;
          top: 17px;
          left: -18px;
          border-right: 25px solid transparent;
          border-bottom: 15px solid #fff;
          border-left: 25px solid transparent;
          -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
        }

        &::after {
          content: '';
          position: absolute;
          top: 17px;
          left: calc(100% - 18px);
          border-right: 25px solid transparent;
          border-bottom: 15px solid #5074ac;
          border-left: 25px solid transparent;
          -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
        }
      }
      &:last-child {
        &::before {
          content: '';
          position: absolute;
          top: 17px;
          left: -18px;
          border-right: 25px solid transparent;
          border-bottom: 15px solid #fff;
          border-left: 25px solid transparent;
          -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
        }
        &::after {
          content: '';
          position: absolute;
          top: 17px;
          left: calc(100% - 18px);
          border-right: 25px solid transparent;
          border-bottom: 15px solid #fa6d63;
          border-left: 25px solid transparent;
          -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
        }
      }
    }

    @media ${breakpoint.sp} {
      width: 90%;
      margin-left: 16px;
      margin-right: 16px;

      border-radius: 8px;

      &:not(:first-child) {
        margin-top: 50px;
      }

      &:not(:last-child)::after {
        content: ' ';
        position: absolute;
        top: calc(50% + 20px);
        left: calc(50% - 15px);
        width: 30px;
        height: 30px;
        border: 3px solid;
        border-color: transparent transparent #ddd #ddd;
        transform: rotate(-45deg);
      }
    }
  }

  & > li > span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    -webkit-transform: translateY(-50%) translateX(-50%);
  }
`;

const TriangleFlowDetail = styled.div`
  display: inline-block;
  text-align: center;

  width: 50px;
  height: 50px;
  margin: 20px;

  width: 30px;
  height: 30px;
  border: 3px solid;
  border-color: transparent transparent #ddd #ddd;
  transform: rotate(-45deg);
`;

const FlowDetailBox = styled.div`
  background: #f5f5f5;
  padding: 30px 32px 40px;

  @media ${breakpoint.sp} {
    margin-left: 16px;
    margin-right: 16px;
    border-radius: 10px;
  }

  &:not(:first-child) {
    margin-top: calc(86px - 70px);
  }

  & > h3 {
    font-size: 22px;
    margin-bottom: 36px;
  }

  & > ul {
    & > li {
      display: flex;
      -webkit-flex-wrap: wrap;
      flex-wrap: wrap;
      &:not(:first-child) {
        margin-top: 33px;
      }

      @media ${breakpoint.sp} {
        width: 90%;
        margin-left: 16px;
        margin-right: 16px;
      }
    }
  }
`;

const FlowDetailImg = styled(LazyLoadImage)`
  max-width: 220px;
  margin-right: 24px;
  border-radius: 4px;

  @media ${breakpoint.sp} {
    max-width: none;
    width: 100%;
  }
`;

const FlowDetailDesc = styled.div`
  max-width: calc(90% - 244px);
  text-align: left;

  & > h4 {
    font-size: 16px;
  }

  & > p {
    font-size: 14px;
    margin-top: 16px;
  }

  @media ${breakpoint.sp} {
    max-width: none;
    margin-top: 12px;
  }
`;

const BGGreen = styled(Section)`
  max-width: none;
  background: #e7f6f1;
  padding: 44px 0 56px;

  @media ${breakpoint.tabOnly} {
    width: 100vw;
    margin: 40px 0;
  }

  @media ${breakpoint.sp} {
    width: 100vw;
    margin: 40px 0;
    padding: 44px 16px 56px;
  }
`;

const OptionListWrap = styled.div`
  max-width: 800px;
  margin: 0 auto;
  @media ${breakpoint.tabOnly} {
    max-width: none;
  }
  @media ${breakpoint.sp} {
    max-width: none;
  }
`;

const OptionList = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;

  @media ${breakpoint.tabOnly} {
    padding: 0 16px;
    grid-gap: 16px;
  }

  @media ${breakpoint.sp} {
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }

  & > li {
    background-color: #fff;
    border-radius: 12px;
    border: 1px solid #c4c4c4;
  }
`;

const OptionTitle = styled.p`
  background-color: #6ec199;
  height: 60px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  border-radius: 10px 10px 0 0;
  position: relative;

  & > span {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    -webkit-transform: translateY(-50%) translateX(-50%);
  }
`;

const OptionDesc = styled.p`
  text-align: left;
  padding: 16px;
  font-size: 14px;
`;
